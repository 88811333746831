import React, { Suspense } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { CLoading } from "@common/components/controls";
import { CScrollToTop } from "@common/components/others";
import theme from "@theme";
import { ViewAudioPage } from "@audio-module/pages";
import CMainLayout from "../common/components/layout/CMainLayout";
import { Route } from "react-router-dom";

const Routes = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<CLoading />}>
          <CScrollToTop />
          <Switch>
            {/* =========== */}

            <Route
              component={ViewAudioPage}
              element={<ViewAudioPage />}
              path="/view-audio"
              exact
            />
            {/* =========== */}

            <CMainLayout />
          </Switch>
        </Suspense>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default Routes;
