import * as PropTypes from "prop-types";

import React, { useState, memo, useEffect, useCallback } from "react";

import {
  Autocomplete,
  TextField,
  debounce,
  CircularProgress,
  Typography,
  Box,
  IconButton,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";

const CLoadMore = ({
  disabled,
  display,
  equalValue,
  error,
  errorText,
  hasNextPage,
  isDelete,
  loading,
  multiple,
  options,
  placeholder,
  value,
  sx,
  handleDelete,
  loadMore,
  onChange,
  onClose,
  onKeyDown,
  onSearch,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [nodes, setNodes] = useState(null);
  const [position, setPosition] = useState(0);

  const debounceSearch = useCallback(
    (value) => {
      if (value) debounce(onSearch(value), 600);
      // console.log("debounceSearch", value);
    },
    [onSearch]
  );

  // console.log("onInputChange", value);

  const compare = useCallback(
    (option, value) => {
      equalValue
        ? option.songId === value.songId
        : value === undefined ||
          value === "" ||
          option?.id === value?.id ||
          option?.value === value?.value;
    },
    [equalValue]
  );

  const getOptionLabel = (option) => {
    if (option !== undefined) {
      if (typeof option === "string") return option;

      if (option?.inputValue) return option.inputValue;

      if (Array.isArray(display)) {
        let stringg = "";
        display.forEach((e, i) => {
          i !== display.length - 1
            ? (stringg += `${option[e]} - `)
            : (stringg += `${option[e]}`);
        });
        return stringg;
      }

      return option?.[display];
    } else {
      return "";
    }
  };

  const onInputChange = async (e, v, state) => {
    if (state === "reset" && v === "") {
      setInputValue("");
      !equalValue && debounceSearch("");
    }

    if (state === "reset" && v !== "") {
      setInputValue(v);
    }

    if (state === "input") {
      setInputValue(v);
      debounceSearch(v);
    }
    // console.log("onInputChange", v);
  };

  const onScroll = (event) => {
    const { currentTarget } = event;
    setNodes(currentTarget);

    if (
      Math.ceil(currentTarget.scrollTop) + currentTarget.clientHeight >=
        currentTarget.scrollHeight &&
      hasNextPage
    ) {
      loadMore();
      setPosition(currentTarget.scrollHeight);
    }
  };

  const renderInput = useCallback(
    (params) => (
      <TextField
        placeholder={placeholder}
        error={error}
        {...params}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    ),
    [error, loading, placeholder]
  );

  const renderOption = useCallback(
    (_, option) => {
      if (option) {
        return (
          <Box
            key={_["data-option-index"]}
            sx={{
              fontSize: "14px",
              color: "#000",
              overflow: "auto",
            }}
            {..._}
          >
            {_.key}

            {isDelete && (
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDelete(option)}
                sx={{ marginLeft: "auto" }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        );
      }
    },
    [handleDelete, isDelete]
  );

  useEffect(() => {
    if (nodes && position) nodes.scrollTop = position - 50;
  }, [nodes, position]);

  return (
    <>
      <Autocomplete
        disabled={disabled}
        inputValue={inputValue}
        loading={loading}
        multiple={multiple}
        options={options}
        value={value}
        isOptionEqualToValue={compare}
        getOptionLabel={getOptionLabel}
        renderInput={renderInput}
        renderOption={renderOption}
        onChange={onChange}
        onClose={onClose}
        onInputChange={onInputChange}
        onKeyDown={onKeyDown}
        filterOptions={(x) => x}
        sx={
          sx
            ? sx
            : {
                // "background": "#fff",
                borderRadius: "4px",
                height: "44px",
                "& .MuiFormLabel-root": { fontSize: "14px" },
                "& .MuiInputBase-root .Mui-disabled": { background: "e6e6e6" },
                "& .MuiInputBase-input": {
                  fontSize: "14px",
                  minWidth: "55px !important",
                  padding: "4px 4px 3.5px 2px !important",
                },
                "& .MuiOutlinedInput-notchedOutline": { height: "50px" },
              }
        }
        ListboxProps={{
          sx: { maxHeight: "260px" },
          onScroll,
        }}
      />

      <Typography variant="body1">
        {errorText !== "undefined" && errorText}
      </Typography>
    </>
  );
};

CLoadMore.propTypes = {
  disabled: PropTypes.bool,
  display: PropTypes.array,
  equalValue: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  hasNextPage: PropTypes.bool,
  isDelete: PropTypes.bool,
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  sx: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  handleDelete: PropTypes.func,
  // loadMore: PropTypes.func.isRequired,
  // onChange: PropTypes.func.isRequired,
  oadMore: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onKeyDown: PropTypes.func,
  // onSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
};

export default memo(CLoadMore);
