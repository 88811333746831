export const AUTH = {
  LOGIN: "/auth/staff/login",
  PROFILE: "/auth/get-profile",
  REFETCH_TOKEN: "/auth/renew-token",
  LOGOUT: "/auth/logout",
};

export const NEWSFEEDS = {
  GET: "/newsfeeds/all",
  GET_BY_CODE: "/newsfeeds",
  UPLOAD_FILE: "/newsfeeds/files/upload",
  POST: "/newsfeeds",
  PUT: "/newsfeeds",
  DELETE: "/newsfeeds",
};

export const NEWSFEED_TYPES = {
  GET: "/newsfeeds/type/all",
  POST: "/newsfeeds/type",
  PUT: "/newsfeeds/type",
  DELETE: "/newsfeeds/type",
  GET_BY_CODE: "/newsfeeds/type",
};

export const NOTIFICATION_CONTENTS = {
  GET: "/notification/contents/all",
  GET_BY_CODE: "/notification/contents",
  POST: "/notification/contents",
  PUT: "/notification/contents",
  DELETE: "/notification/contents",
  HISTORIES: "/notification/contents/histories",
};

export const NOTIFICATION_SEGMENTS = {
  GET: "/notification/segments/all",
  GET_BY_CODE: "/notification/segments",
  POST: "/notification/segments",
  PUT: "/notification/segments",
  DELETE: "/notification/segments",
  HISTORIES: "/notification/segments/histories",
  CUSTOMERS: "/notification/segments/customers",
};

export const NOTIFICATION_CAMPAIGNS = {
  GET: "/notification/campaigns/all",
  GET_BY_CODE: "/notification/campaigns",
  POST: "/notification/campaigns",
  PUT: "/notification/campaigns",
  DELETE: "/notification/campaigns",
  HISTORIES: "/notification/campaigns/histories",
};

export const NOTIFICATION_CATEGORIES = {
  GET: "/notification/categories/all",
  POST: "/notification/categories",
  DELETE: "/notification/categories",
};

export const CUSTOMERS = {
  GET: "/cms-customers/all",
  GET_BY_CUSTOMER_CODE: "/cms-customers",
  MERGE: "/cms-customers",
  GET_TRANSACTION_HISTORIES: "/cms-customers/transaction/histories",
  GET_POINT_HISTORIES: "/cms-customers/point/histories",
  GET_VOUCHER_HISTORIES: "/cms-customers/voucher/histories",
  REVOKE_RECEIPT: "/cms-customers/transaction",
  RECALL: "/cms-customers/point/recall",
  GET_STORES: "/stores",
  GET_ROOMS: "/rooms/getByStore",
  INVOICES: "cms-points/invoices",
  REPEAT: "cms-points/repeat",
};

export const VOUCHERS = {
  GET: "/voucher/promotions/all",
  GET_BY_CODE: "/voucher/promotions",
  POST: "/voucher/promotions",
  PUT: "/voucher/promotions",
  DELETE: "/voucher/promotions",
  GET_SALES: "/voucher/sales/all",
  ASSIGN: "/voucher/voucher-gifts/assign",
  DISABLE: "/voucher/voucher-gifts/disable",
};

export const TRANSACTION_HISTORIES = { GET: "/histories/transaction/all" };

export const VOUCHER_HISTORIES = { GET: "/histories/voucher/all" };

export const POINT_HISTORIES = { GET: "/histories/point/all" };

export const RATINGS = {
  GET: "/ratings/questions/all",
  GET_BY_CODE: "/ratings/questions",
  POST: "/ratings/questions",
  PUT: "/ratings/questions",
  DELETE: "/ratings/questions",
};

export const CUSTOMER_RATINGS = {
  GET: "/ratings/all",
  GET_BY_CODE: "/ratings",
};

export const INVENTORIES = {
  GET: "/inventories/all",
  GET_BY_CODE: "/inventories",
  POST: "/inventories",
  PUT: "/inventories",
  DELETE: "/inventories",
};

export const SONGS = {
  GET_GROUPS: "/songs/groups/all",
  GET: "/songs/all",
  GET_BY_CODE: "/songs",
  POST: "/songs",
  PUT: "/songs",
  DELETE: "/songs",
};

export const POLICIES = {
  GET_BY_CODE: "/policies/get-by-code",
  GET_BY_CATEGORY: "/policies/get-by-category",
  GET_BY_ID: "/policies",
  POST: "/policies",
  PUT: "/policies",
  DELETE: "/policies",
};

export const CONFIG_EVENTS = {
  GET: "/config-events/all",
  GET_BY_ID: "/config-events",
  POST: "/config-events",
  PUT: "/config-events",
  DELETE: "/config-events",
};

export const HOLIDAY = {
  GET: "/holidays/all",
  GET_BY_ID: "/holidays",
  POST: "/holidays",
  PUT: "/holidays",
  DELETE: "/holidays",
};

export const TIME_CONFIG = {
  GET: "/time-configs/all",
  GET_BY_ID: "/time-configs",
  POST: "/time-configs",
  PUT: "/time-configs",
  DELETE: "/time-configs",
};

export const POPUP = {
  GET: "/popups/all",
  GET_BY_ID: "/popups",
  POST: "/popups",
  PUT: "/popups",
  DELETE: "/popups",
};

export const RESERVATION = {
  GET: "/reservations/all",
  CANCEL: "/reservations/cancel",
  COMFIRM: "/reservations/confirm",
};

export const FIREBASE = {
  PUT: "/auth/firebase/token",
};

export const FILES = { UPLOAD_FILE: "/files/upload" };

export const IMAGES = {
  GET: "/images/all",
  GET_BY_ID: "/images",
  CREATE: "/images",
  UPLOAD: "/files/uploads",
  UPDATE: "/images",
  DELETE: "/images",
};

export const USERS = {
  GET: "/accounts/all",
  GET_BY_ID: "/accounts",
  POST: "/accounts",
  PUT: "/accounts",
  DELETE: "/accounts",
};

export const PERMISSIONS = {
  GET: "/permissions",
};

export const AUDIO = {
  GET: "/audio/all",
  GET_BY_ID: "/audio",
  PUT: "/audio",
  DELETE: "/audio",
  SHARE: "/audio/shared",
};

export const UNASSIGNED = {
  GET: "/audio/unassigned-customers/all",
  GET_BY_ID: "/audio/unassigned-customers",
  UPDATE: "/audio/unassigned-customers",
  SEND: "/audio/unassigned-customers", //  /:id/send
  DELETE: "/audio/unassigned-customers",
};
