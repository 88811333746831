import DashboardIcon from "@mui/icons-material/Dashboard";
import FeedIcon from "@mui/icons-material/Feed";
import NotificationsIcon from "@mui/icons-material/Notifications";
import RestoreIcon from "@mui/icons-material/Restore";
import StarIcon from "@mui/icons-material/Star";
import InfoIcon from "@mui/icons-material/Info";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import DiscountIcon from "@mui/icons-material/Discount";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import LanguageIcon from "@mui/icons-material/Language";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ElevenMpIcon from "@mui/icons-material/ElevenMp";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote";

export const routeNames = [
  {
    display_name: "dashboard",
    route: "/dashboard",
    icon: DashboardIcon,
    key: "home",
  },
  {
    display_name: "newsfeed",
    route: "/newsfeed",
    icon: FeedIcon,
    route_add: "/newsfeed/add",
    key: "newsfeed",
  },
  {
    display_name: "newsfeed_types",
    route: "/bulletin_type",
    icon: FeedIcon,
    route_add: "/bulletin_type/add",
    key: "bulletin_type",
  },
  {
    display_name: "notification",
    icon: NotificationsIcon,
    route: "/notification",
    key: "notification",
    children: [
      {
        label: "notificontent",
        route: "/notification/content",
      },
      {
        label: "noticampaign",
        route: "/notification/campaign",
      },
      {
        label: "notisegment",
        route: "/notification/segment",
      },
      {
        label: "popup",
        route: "/notification/popup",
      },
    ],
  },
  {
    display_name: "voucher",
    route: "/voucher",
    icon: DiscountIcon,
    route_add: "/voucher/add",
    key: "voucher",
  },
  {
    display_name: "customer",
    route: "/customer",
    icon: SupervisorAccountIcon,
    route_add: "/customer/add",
    key: "customer",
  },
  {
    display_name: "history",
    route: "/histories",
    icon: RestoreIcon,
    key: "histories",
    children: [
      {
        label: "point_history",
        route: "/histories/point",
      },
      {
        label: "transaction_history",
        route: "/histories/transaction",
      },
      {
        label: "voucher_history",
        route: "/histories/promotion",
      },
    ],
  },
  {
    display_name: "rating",
    route: "/rating",
    icon: StarIcon,
    key: "rating",
    children: [
      {
        label: "rating_questions",
        route: "/rating/questions",
      },
      {
        label: "rating_customers",
        route: "/rating/person",
      },
    ],
  },
  {
    display_name: "inventory",
    route: "/inventory",
    icon: ContentPasteIcon,
    key: "inventory",
  },
  {
    display_name: "songs",
    route: "/song",
    icon: AudiotrackIcon,
    key: "song",
  },
  {
    display_name: "images",
    route: "/images",
    icon: CameraAltIcon,
    key: "images",
  },
  {
    display_name: "book_room",
    route: "/reservation",
    icon: ElevenMpIcon,
    key: "reservation",
  },
  {
    display_name: "information",
    route: "/policies",
    icon: InfoIcon,
    key: "policies",
    children: [
      {
        label: "qa",
        route: "/policies/qa",
      },
      {
        label: "membership_policy",
        route: "/policies/membership",
      },
      {
        label: "referral_policy",
        route: "/policies/referral",
      },
      {
        label: "transfer_policy",
        route: "/policies/transfer",
      },
      {
        label: "contact",
        route: "/policies/contact",
      },
      {
        label: "company_infos",
        route: "/policies/company_infos",
      },
      {
        label: "personal_wallpaper",
        route: "/policies/event",
      },
      {
        label: "holiday",
        route: "/policies/holiday",
      },
      {
        label: "book-rom",
        route: "/policies/time-configs",
      },
    ],
  },
  {
    display_name: "language",
    route: "/language",
    icon: LanguageIcon,
    children_language: [{ label: "en" }, { label: "vn" }],
    key: "language",
  },
  {
    display_name: "users",
    route: "/users",
    key: "account",
    icon: AccessibilityNewIcon,
    children: [
      {
        label: "users_list",
        route: "/users",
      },
    ],
  },
  {
    display_name: "recording",
    route: "/recording",
    icon: SettingsRemoteIcon,
    key: "audio",
    children: [
      {
        label: "recording_list",
        route: "/recording/full",
      },
      {
        label: "recording_info",
        route: "/recording/not-info",
      },
    ],
  },
  // {
  //   display_name: "account",
  //   route: "/account",
  //   icon: AccountCircleIcon,
  //   children: [
  //     {
  //       label: "accountinfor",
  //       route: "/account",
  //     },
  //     {
  //       label: "logout",
  //       route: "/account/logout",
  //     },
  //   ],
  // },
];

export const accountRouter = {
  display_name: "account",
  route: "/account",
  icon: AccountCircleIcon,
  children: [
    // {
    //   label: "accountinfor",
    //   route: "/account",
    // },
    {
      label: "logout",
      route: "/account/logout",
    },
  ],
};
