/* eslint-disable operator-linebreak */
import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";

import { accountRouter, routeNames } from "@routes/paths";

import { setNewsfeedFilter } from "@common/actions/newsfeed.action";
import { setNotificationContentFilter } from "@common/actions/notification-content.action";
import { setNotificationCampaignFilter } from "@common/actions/notification-campaign.action";
import { setNotificationSegmentFilter } from "@common/actions/notification-segment.acion";
import { setVoucherFilter } from "@common/actions/voucher.action";
import {
  setCustomerFilter,
  setCustomer,
} from "@common/actions/customer.action";
import { setInventoryFilter } from "@common/actions/inventory.action";
import { setSongFilter } from "@common/actions/song.action";

import { logout } from "@utils/axios/request.axios";
import { Box } from "@mui/material";
import CNavDropdown from "./components/CNavDropdown";
import { checkPermissionNotification } from "../../../../../firebase/firebase";
import { setConfigEventsFilter } from "@common/actions/config-events.action";
import { setConfigPopupFilter } from "@common/actions/popup.action";
import { setHolidayFilter } from "@common/actions/holiday.action";
import { setConfigImageFilter } from "../../../../actions/image.action";
import { filterRoutes } from "@utils/func";

const CHeader = () => {
  const dispatch = useDispatch();

  const _user = useSelector((state) => state.auth.user);

  const location = useLocation();

  const { t, i18n } = useTranslation();

  const [url, setUrl] = useState(null);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [route, setRoute] = useState(routeNames || []);

  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  const setFilters = () => {
    dispatch(setNewsfeedFilter(null));
    dispatch(setNotificationContentFilter(null));
    dispatch(setNotificationCampaignFilter(null));
    dispatch(setNotificationSegmentFilter(null));
    dispatch(setVoucherFilter(null));
    dispatch(setCustomerFilter(null));
    dispatch(setCustomer(null));
    dispatch(setInventoryFilter(null));
    dispatch(setSongFilter(null));
    dispatch(setConfigEventsFilter(null));
    dispatch(setConfigPopupFilter(null));
    dispatch(setHolidayFilter(null));
    dispatch(setConfigImageFilter(null));
    setToggleDropdown(false);
  };

  const logOut = useCallback(async (e) => {
    e.preventDefault();
    await logout();
  }, []);

  const switchToVN = () => {
    i18n.changeLanguage("vn");
  };

  const switchToEn = () => {
    i18n.changeLanguage("en");
  };

  const onClose = () => {
    setToggleDropdown(false);
  };

  useEffect(() => {
    if (_user?.permissions) {
      const routePer = filterRoutes(_user?.permissions, routeNames);

      setRoute(routePer);
      console.log("aaaaaa", routePer);
    }
  }, [_user]);

  useEffect(() => {
    checkPermissionNotification();
  }, []);

  return (
    <div style={{ position: "relative", minHeight: "75px" }}>
      <div className={`c-nav-container ${toggleDropdown ? "c-nav-res" : ""}`}>
        <Box component="section" className="c-nav">
          <Navbar
            collapseOnSelect
            expand="xxl"
            className="gap-3 "
            expanded={toggleDropdown}
          >
            <Navbar.Toggle
              aria-controls="navbarScroll"
              data-bs-target="#navbarScroll"
              onClick={() =>
                setToggleDropdown(toggleDropdown ? false : "expanded")
              }
            />

            <Navbar.Collapse id="navbarScroll">
              <Nav className="flex-column ">
                {route.map((item, index) => (
                  <Box key={index}>
                    {item.children && (
                      <>
                        <CNavDropdown
                          data={item}
                          url={url}
                          setFilters={setFilters}
                          logOut={logOut}
                        />
                      </>
                    )}

                    {/* Use with language */}
                    {item.children_language && (
                      <NavDropdown
                        title={
                          <>
                            <item.icon className="navbar-icon" />
                            {/* {t(`route.${item.display_name}`)} */}
                            <span> {t(`route.${item.display_name}`)}</span>
                            <ArrowDropDownIcon />
                          </>
                        }
                      >
                        {item.children_language.map((item) => (
                          <NavDropdown.Item
                            key={item.label}
                            onClick={
                              item.label === "en" ? switchToEn : switchToVN
                            }
                            className={
                              window.localStorage.getItem("i18nextLng") ===
                              item.label
                                ? "active"
                                : // eslint-disable-next-line operator-linebreak, indent
                                  ""
                            }
                          >
                            {t(`route.${item.label}`)}
                          </NavDropdown.Item>
                        ))}
                      </NavDropdown>
                    )}

                    {!item.children && !item.children_language && (
                      <Nav.Link
                        as={Link}
                        to={item.route}
                        className={url?.includes(item.route) ? "active" : ""}
                        key={index}
                        onClick={() => {
                          setFilters();
                          onClose();
                        }}
                      >
                        <item.icon className="navbar-icon" />
                        <span> {t(`route.${item.display_name}`)}</span>
                      </Nav.Link>
                    )}
                  </Box>
                ))}
              </Nav>
            </Navbar.Collapse>
          </Navbar>

          <Navbar
            collapseOnSelect
            expand="xxl"
            className="gap-3 account"
            expanded={toggleDropdown}
          >
            <Navbar.Collapse id="navbarScroll">
              <Nav className="flex-column ">
                <Box>
                  <NavDropdown
                    active={url?.includes(accountRouter?.route)}
                    title={
                      <>
                        <accountRouter.icon className="navbar-icon" />
                        {/* {t(`route.${item.display_name}`)} */}
                        <span> {t(`route.${accountRouter.display_name}`)}</span>
                        {/* <ArrowDropDownIcon /> */}
                      </>
                    }
                    key={accountRouter.display_name}
                  >
                    {accountRouter.children.map((item) => (
                      <NavDropdown.Item
                        as={Link}
                        to={item.route}
                        className={url?.includes(item.route) ? "active" : ""}
                        key={item.label}
                        onClick={item.label === "logout" ? logOut : setFilters}
                      >
                        {t(`route.${item.label}`)}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                </Box>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Box>
      </div>
    </div>
  );
};

export default withTranslation()(CHeader);
