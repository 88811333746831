import * as PropTypes from "prop-types";

import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

import { isLogin } from "@utils/func";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? (
          <Component {...props} />
        ) : (
          location.pathname !== "/view-audio" && <Redirect to="/" />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default PrivateRoute;
