import * as PropTypes from "prop-types";

import React from "react";
import { Route, Redirect } from "react-router-dom";

import { isLogin } from "@utils/func";

/*
  restricted: false => meaning public route | true => meaning restricted route
*/

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin() && restricted ? (
          <Redirect to={"/"} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

PublicRoute.propTypes = {
  component: PropTypes.func.isRequired,
  restricted: PropTypes.bool,
};

export default PublicRoute;
