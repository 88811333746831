import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { routes } from "@routes";

import { getMessaging, isSupported, onMessage } from "firebase/messaging";
import { app, requestPermission } from "../../../../firebase/firebase";

import { filterRoutes, hasReservationChecked, isLogin } from "@utils/func";
import { getReservation } from "@apis/booking-room.api";

import { UserContext } from "@common/context/UserContext";
import { Box } from "@mui/material";

import { Switch } from "react-router-dom";

import { LoginPage } from "@auth-module/pages";

import { CHeader } from "@common/components/layout/Main";
import { CNotFound } from "@common/components/others";
import { PrivateRoute, PublicRoute } from "@common/components/routes";

const CMainLayout = () => {
  const _user = useSelector((state) => state.auth.user);

  const [user, setUser] = useState(null);

  const [noti, setNoti] = useState(false);

  const [bookingData, setBookingData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [route, setRoute] = useState(routes || []);

  const value = useMemo(
    () => ({
      user,
      setUser,
      noti,
      setNoti,
      loading,
      setLoading,
    }),

    [user, noti, loading]
  );

  let intervalId = null;

  const getMessage = async () => {
    if (!(await isSupported())) return;

    const messaging = getMessaging();

    onMessage(messaging, (payload) => {
      let isOriginalTitle = true;

      console.log("useEffect");

      if (payload) {
        setNoti(true);

        document.title = payload.notification.title;

        console.log("noti", payload);

        clearInterval(intervalId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
        intervalId = setInterval(() => {
          if (isOriginalTitle) {
            document.title = "CRM CMS";
          } else {
            document.title = payload?.notification?.title || "CRM CMS";
          }

          isOriginalTitle = !isOriginalTitle;
        }, 1000);
      }
    });
  };

  useEffect(() => {
    getMessage();

    return () => {
      clearInterval(intervalId);
    };
  }, [app]);

  const handleClickAnywhere = () => {
    // clearInterval(intervalId);
    // document.title = "CRM CMS";

    setLoading(true);
    setNoti(true);
  };

  useEffect(() => {
    if (isLogin()) {
      const fetchData = async () => {
        const data = await getReservation({
          pages: 0,
          page: 1,
          status: 0,
        });

        if (data) {
          setBookingData(data?.data);
        }
      };

      fetchData();
    }
  }, [loading]);

  useEffect(() => {
    requestPermission();
    // if(requestPermission)
    // checkPermissionNotification();
  }, []);

  useEffect(() => {
    if (
      isLogin() &&
      bookingData?.amount > 0 &&
      hasReservationChecked(_user?.permissions)
    ) {
      let isOriginalTitle = true;
      document.title = `CRM CMS`;
      clearInterval(intervalId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      intervalId = setInterval(() => {
        if (isOriginalTitle) {
          document.title = "CRM CMS";
        } else {
          document.title = `Có ${bookingData?.amount} đơn đặt phòng mới!`;
        }
        isOriginalTitle = !isOriginalTitle;
      }, 1000);
      // setIntervalId(newIntervalId);
    } else {
      clearInterval(intervalId);
    }

    setLoading(false);
    return () => {
      clearInterval(intervalId);
    };
  }, [isLogin(), bookingData?.amount, intervalId, loading, app]);

  useEffect(() => {
    if (_user?.permissions) {
      const routePer = filterRoutes(_user?.permissions, routes);

      setRoute(routePer);
    }
  }, [_user]);

  return (
    <>
      <UserContext.Provider value={value}>
        <Box
          component="section"
          className="c-layout"
          onClick={handleClickAnywhere}
        >
          <PrivateRoute component={CHeader} />
          <div className="c-body-content">
            <Switch>
              <PublicRoute
                restricted={true}
                component={LoginPage}
                path="/"
                exact
              />

              {route.map((item, index) => (
                <PrivateRoute
                  component={item.component}
                  path={item.path}
                  exact
                  key={index}
                />
              ))}

              <PrivateRoute component={CNotFound} path="*" />
              <PublicRoute component={CNotFound} path="*" />
            </Switch>
          </div>
        </Box>
      </UserContext.Provider>
    </>
  );
};

export default CMainLayout;
