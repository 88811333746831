import { Dashboard } from "../modules/dashboard/pages/index";

import {
  BlogListPage,
  CreateBlogPage,
  UpdateBlogPage,
} from "../modules/newsfeed/pages";

import {
  CreateNotiContentPage,
  NotiContentListPage,
  UpdateNotiContentPage,
} from "../modules/notification-content/pages";

import {
  CreateNotiCampaignPage,
  NotiCampaignListPage,
  UpdateNotiCampaignPage,
} from "../modules/notification-campaigns/pages";

import {
  CreateNotiSegmentPage,
  NotiSegmentListPage,
  UpdateNotiSegmentPage,
} from "../modules/notification-segments/pages";

import {
  CreatePromotionPage,
  PromotionListPage,
  UpdatePromotionPage,
} from "../modules/promotion/pages";

import { CustomerPage, UpdateCustomerPage } from "../modules/customer/pages";
import { PointHistoryPage } from "../modules/history-point/pages";
import { TransactionHistoryPage } from "../modules/history-transaction/pages";
import { VoucherHistoryPage } from "../modules/history-voucher/pages";

import {
  RatingCustomerListPage,
  UpdateRatingCustomerPage,
} from "../modules/rating-customer/pages";

import {
  CreateRatingQuestionPage,
  RatingQuestionListPage,
  UpdateRatingQuestionPage,
} from "../modules/rating-question/pages";

import {
  CreateInventoryPage,
  InventoryListPage,
  UpdateInventoryPage,
} from "../modules/inventory/pages";

import { AccountListPage, AccountUpdatePage } from "../modules/account/pages";
import {
  CreateSongPage,
  SongListPage,
  SynchronizedPage,
  UpdateSongPage,
} from "../modules/songs/pages";

import { UpdateTransferPolicyPage } from "../modules/policy/transfer/pages";
import { UpdateCompanyInfoPage } from "../modules/policy/company-infos/pages";

import {
  ContactListPage,
  UpdateContactPage,
} from "../modules/policy/contact/pages";

import {
  CreateQAPage,
  QAListPage,
  UpdateQAPage,
} from "../modules/policy/qa/pages";

import { UpdateReferralPolicyPage } from "../modules/policy/referral/pages";
import { MembershipPage } from "../modules/policy/membership/pages";
import { UpdateMembershipOffersPage } from "../modules/policy/membership/components/membership-offer/pages";

import {
  UpdateNewsfeedTypePage,
  NewsfeedTypesListPage,
  CreateNewsfeedTypePage,
} from "../modules/newsfeeds-types/pages";
import {
  CreateEventpage,
  EventPage,
  UpdateEventpage,
} from "@policy-module/event/pages";

import {
  CreatePopupPage,
  PopupPage,
  UpdatePopupPage,
} from "@popup-module/pages";
import {
  CreateHolidayPage,
  HolidayPage,
  UpdateHolidayPage,
} from "@policy-module/holiday/pages";
import {
  CreateTimeConfigPage,
  TimeConfigPage,
  UpdateTimeConfigPage,
} from "@policy-module/time-config/pages";
import { BookingRoomPage } from "../modules/booking-room/pages";

import {
  ImageListPage,
  CreateImagepage,
  UpdateImagePage,
} from "../modules/images/pages";

import {
  CreateUserPage,
  UpdateUserPage,
  UserListPage,
} from "../modules/users/pages";

import { ListAudioPage, UpdateAudioPage } from "../modules/audio/pages";
import {
  ListRecordingPage,
  UpdateRecordingPage,
} from "../modules/recording/pages";

const routes = [
  {
    path: "/dashboard",
    component: Dashboard,
    key: "home",
  },
  {
    path: "/newsfeed",
    component: BlogListPage,
    key: "newsfeed",
  },
  {
    path: "/newsfeed/add",
    component: CreateBlogPage,
    key: "newsfeed",
  },
  {
    path: "/newsfeed/:id",
    component: UpdateBlogPage,
    key: "newsfeed",
  },
  {
    path: "/bulletin_type",
    component: NewsfeedTypesListPage,
    key: "bulletin_type",
  },
  {
    path: "/bulletin_type/add",
    component: CreateNewsfeedTypePage,
    key: "bulletin_type",
  },
  {
    path: "/bulletin_type/:id",
    component: UpdateNewsfeedTypePage,
    key: "bulletin_type",
  },
  {
    path: "/notification/content",
    component: NotiContentListPage,
    key: "notification",
  },
  {
    path: "/notification/content/add",
    component: CreateNotiContentPage,
    key: "notification",
  },
  {
    path: "/notification/content/:id",
    component: UpdateNotiContentPage,
    key: "notification",
  },
  {
    path: "/notification/campaign",
    component: NotiCampaignListPage,
    key: "notification",
  },
  {
    path: "/notification/campaign/add",
    component: CreateNotiCampaignPage,
    key: "notification",
  },
  {
    path: "/notification/campaign/:id",
    component: UpdateNotiCampaignPage,
    key: "notification",
  },
  {
    path: "/notification/segment",
    component: NotiSegmentListPage,
    key: "notification",
  },
  {
    path: "/notification/segment/add",
    component: CreateNotiSegmentPage,
    key: "notification",
  },
  {
    path: "/notification/segment/:id",
    component: UpdateNotiSegmentPage,
    key: "notification",
  },
  {
    path: "/notification/popup",
    component: PopupPage,
    key: "notification",
  },
  {
    path: "/notification/popup/add",
    component: CreatePopupPage,
    key: "notification",
  },
  {
    path: "/notification/popup/:id",
    component: UpdatePopupPage,
    key: "notification",
  },
  {
    path: "/voucher",
    component: PromotionListPage,
    key: "voucher",
  },
  {
    path: "/voucher/add",
    component: CreatePromotionPage,
    key: "voucher",
  },
  {
    path: "/voucher/:id",
    component: UpdatePromotionPage,
    key: "voucher",
  },
  {
    path: "/customer",
    component: CustomerPage,
    key: "customer",
  },
  {
    path: "/customer/:id",
    component: UpdateCustomerPage,
    key: "customer",
  },
  {
    path: "/histories/transaction",
    component: TransactionHistoryPage,
    key: "histories",
  },
  {
    path: "/histories/promotion",
    component: VoucherHistoryPage,
    key: "histories",
  },
  {
    path: "/histories/point",
    component: PointHistoryPage,
    key: "histories",
  },
  {
    path: "/rating/person",
    component: RatingCustomerListPage,
    key: "rating",
  },
  {
    path: "/rating/person/:id",
    component: UpdateRatingCustomerPage,
    key: "rating",
  },
  {
    path: "/rating/questions",
    component: RatingQuestionListPage,
    key: "rating",
  },
  {
    path: "/rating/questions/add",
    component: CreateRatingQuestionPage,
    key: "rating",
  },
  {
    path: "/rating/questions/:id",
    component: UpdateRatingQuestionPage,
    key: "rating",
  },

  {
    path: "/account",
    component: AccountListPage,
  },
  {
    path: "/account/:id",
    component: AccountUpdatePage,
  },
  {
    path: "/inventory",
    component: InventoryListPage,
    key: "inventory",
  },
  {
    path: "/inventory/add",
    component: CreateInventoryPage,
    key: "inventory",
  },
  {
    path: "/inventory/:id",
    component: UpdateInventoryPage,
    key: "inventory",
  },
  {
    path: "/song",
    component: SongListPage,
    key: "song",
  },
  {
    path: "/song/add",
    component: CreateSongPage,
    key: "song",
  },
  {
    path: "/songs/sync",
    component: SynchronizedPage,
    key: "song",
  },
  {
    path: "/song/:id",
    component: UpdateSongPage,
    key: "song",
  },

  {
    path: "/policies/membership",
    component: MembershipPage,
    key: "policies",
  },
  {
    path: "/policies/membership/:id",
    component: UpdateMembershipOffersPage,
    key: "policies",
  },
  {
    path: "/policies/qa",
    component: QAListPage,
    key: "policies",
  },
  {
    path: "/policies/qa/add",
    component: CreateQAPage,
    key: "policies",
  },
  {
    path: "/policies/qa/:id",
    component: UpdateQAPage,
    key: "policies",
  },
  {
    path: "/policies/company_infos",
    component: UpdateCompanyInfoPage,
    key: "policies",
  },
  {
    path: "/policies/transfer",
    component: UpdateTransferPolicyPage,
    key: "policies",
  },
  {
    path: "/policies/referral",
    component: UpdateReferralPolicyPage,
    key: "policies",
  },
  {
    path: "/policies/contact",
    component: ContactListPage,
    key: "policies",
  },
  {
    path: "/policies/contact/:id",
    component: UpdateContactPage,
    key: "policies",
  },
  {
    path: "/policies/event",
    component: EventPage,
    key: "policies",
  },
  {
    path: "/policies/event/add",
    component: CreateEventpage,
    key: "policies",
  },
  {
    path: "/policies/event/:id",
    component: UpdateEventpage,
    key: "policies",
  },
  {
    path: "/policies/holiday",
    component: HolidayPage,
    key: "policies",
  },
  {
    path: "/policies/holiday/add",
    component: CreateHolidayPage,
    key: "policies",
  },
  {
    path: "/policies/holiday/:id",
    component: UpdateHolidayPage,
    key: "policies",
  },
  {
    path: "/policies/time-configs",
    component: TimeConfigPage,
    key: "policies",
  },
  {
    path: "/policies/time-configs/add",
    component: CreateTimeConfigPage,
    key: "policies",
  },
  {
    path: "/policies/time-configs/:id",
    component: UpdateTimeConfigPage,
    key: "policies",
  },
  {
    path: "/reservation",
    component: BookingRoomPage,
    key: "reservation",
  },
  {
    path: "/images",
    component: ImageListPage,
    key: "images",
  },
  {
    path: "/images/add",
    component: CreateImagepage,
    key: "images",
  },
  {
    path: "/images/:id",
    component: UpdateImagePage,
    key: "images",
  },
  {
    path: "/users",
    component: UserListPage,
    key: "account",
  },
  {
    path: "/users/add",
    component: CreateUserPage,
    key: "account",
  },
  {
    path: "/users/:id",
    component: UpdateUserPage,
    key: "account",
  },
  {
    path: "/recording/full",
    component: ListAudioPage,
    key: "audio",
  },
  {
    path: "/recording/full/:id",
    component: UpdateAudioPage,
    key: "audio",
  },
  {
    path: "/recording/not-info",
    component: ListRecordingPage,
    key: "audio",
  },
  {
    path: "/recording/not-info/:id",
    component: UpdateRecordingPage,
    key: "audio",
  },
];

export { routes };
